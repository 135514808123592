import React, { useState, useContext, useEffect, useMemo, useRef, useCallback } from "react";
import {
  Header,
  ProfileDropDown,
  Footer,
  LogOutPopUp,
} from "../../components";
import { headerImage } from "../../constants/HeaderInputs";
import "./Home.scss";
import { AppContext } from "../../App";
import { useHistory, useRouteMatch, useLocation, matchPath } from "react-router-dom";
import { App } from '../app/App';
import { AbilityContext } from 'common-components-spa';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ResponsiveNavigationMenu from "../../components/responsive-navigation-menu/ResponsiveNavigationMenu";
import { handleLoginLogoutEvents } from "../../utils/autobahn/autobahnHandler";
import { routerBasePath } from '../../constants/Config';
import { adminLinkConsts } from "../../constants/AdminLink"
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { mobileRoutes } from '../../constants/Config';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  }
}));

export function Home() {
  // hook to maintain open and close state for popUp  and footer popUp
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [showAccesibilityPopup, setShowAccesibilityPopup] = useState(false);
  const [showlogOutPopUp, setShowlogOutPopUp] = useState(false);
  const [selectedHeader, setSelectedHeader] = useState('');
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [spaRoutes, setSpaRoutes] = useState(null);
  const [activePath, setActivePath] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [assessmentPage, setAssessmentPage] = useState("")

  const [isNotificationPopupOpen, setIsNotificationPopupOpen] = useState(false);
  const classes = useStyles();
  const { pathname } = useLocation();
  const appContext = useContext(AppContext);
  const ability = useContext(AbilityContext);
  const {
    headerDetails,
    footerDetails,
    iesSession,
    userName,
    defaultSpaId,
    selectedSpaId,
    setSelectedSpaId,
    // activeHubInfo,
    translate: t,
    wrapperLevelErrorPage,
    region,
    fetchSpasInRegion,
    availableSPAs,
    setAllowedSPAsInRegion,
    spaDetailsList: spaConfig,
    setIsOnlyStudentHomeSpa,
    navItems,
    setNavItmes,
    isOnlyStudentHomeSpa,
    isPearsonAdmin,
    setSelectedpath
  } = appContext;
  const { path: selectedPath } = useRouteMatch();
  const theme = useTheme();
  const isMobile = !useMediaQuery('(min-width:768px)');
  const assessmentPageRef = useRef(null)

  useEffect(() => {
    window.addEventListener("message", messageListener)
    return () => window.removeEventListener("message", messageListener)
  }, [])

  useEffect(() => {
    //Set routes to local storage so that wrapper can pick it up
    let savedRoutes = localStorage.getItem("mobileRoutes");
    const event = new Event("mobileRoutes")
    if (!savedRoutes) {
      localStorage.setItem("mobileRoutes", "");
      window.dispatchEvent(event)
    }
    savedRoutes = savedRoutes ? JSON.parse(savedRoutes) : {};
    const updatedRoutes = { ...savedRoutes, "lesson-plan": mobileRoutes }
    localStorage.setItem("mobileRoutes", JSON.stringify(updatedRoutes));
    window.dispatchEvent(event);
  },[])

  const messageListener = useCallback((e) => {
    let key = "data";
    let data = e[key];
    if (typeof (data) === "string" &&
      data === "assessmentdashboard" ||
      data === "assessmentbuilder" ||
      data === "assessmentlibrary" ||
      data === "assessmentmarkentry"
    ) {
      assessmentPageRef.current = data;
      setAssessmentPage(data);
    }
  }, [])

  const activeHubDropdownOptions = useMemo(() => {
    let mobileOptions = []
    if (isMobile) {
      mobileOptions = [
        {
          label: "Notifications",
          action: (ev) => {
            openNotificationPopup(ev);
            setIsOpen(false)
          }
        },
        {
          label: "Pearson Help",
          action: () => window.open("https://support.pearson.com/uk/s/")
        }
      ]
    }
    const dropdownOpts = [
      {
        label: "Manage Account",
        action: () => redirectLink('userProfile'),
      },
      {
        label: "Course Status",
        action: () => redirectLink('courseStatus'),
      },
      {
        label: "Logout",
        action: openLogOutPopUp,
      },
      ...mobileOptions
    ]
    const allDropdownOpts = (pathname.includes('/digitalLibrary/list/') || pathname.includes('/alfresco/player/')) ? dropdownOpts.filter(opt => opt.label !== 'Notifications') : dropdownOpts;
    return allDropdownOpts.filter(opt => !((isOnlyStudentHomeSpa || !isPearsonAdmin) && opt.label === "Course Status"))
  }, [isMobile, isOnlyStudentHomeSpa, isPearsonAdmin])


  //callback for header image click
  function openLogOutPopUp() {
    localStorage.setItem("openLogoutPopup", true);
    setShowlogOutPopUp(!showlogOutPopUp);
    setIsOpen(false);
  }
  const redirectLink = (url) => {
    setIsOpen(false);
    setSelectedSpaId(url)
    setSelectedpath('')
  }
  function closeLogOutPopUp() {
    localStorage.setItem("openLogoutPopup", "");
    setShowlogOutPopUp(false);
  }
  function handleHeaderClick() {
    const defaultSpaId = spaConfig[0].spaId;
    const newEncodedApplicationId = encodeURIComponent(defaultSpaId);
    setSelectedSpaId(defaultSpaId);
    const newUrl = selectedPath.replace(":applicationId?", newEncodedApplicationId);
    history.push(newUrl);

  }
  /**
   * Use to fix the double scroll and fix sidebar issue
   * bug - ACTHB-1277
   */
  useEffect(() => {
    const prodAssessmentSPAId = 'urn:pearson:gps:spa:d051717f-cb63-4563-969c-da8032901aeb';
    const nonProdAssessmentSPAId = 'urn:pearson:gps:spa:4a3b2922-518b-4a6d-b18e-45f4b1b047dd';
    if (selectedSpaId == nonProdAssessmentSPAId || selectedSpaId == prodAssessmentSPAId) {
      document.body.classList.add('assessment-dashboard');
    } else {
      document.body.classList.remove('assessment-dashboard');
    }
  }, [selectedSpaId]);

  // function to handle open and close state of popUp components
  function showDropDown(event) {
    if (event) {
      event.stopPropagation();
    }
    setIsOpen(!isOpen);
  }

  function handleLogout(event) {
    event.stopPropagation();
    event.preventDefault();
    closeLogOutPopUp();
    localStorage.removeItem('isRedirectedToMat');
    handleLoginLogoutEvents("logout");
    window.dataLayer = [];
    sessionStorage.removeItem("asset-manifest-/lesson-plan");
    iesSession.logout();
  }

  function onAccesibilityClick() {
    setShowAccesibilityPopup(true);
  }

  const headerDetailsProps = headerDetails
    ? { headerDetails: headerDetails }
    : {};

  useEffect(() => {
    const subscription = fetchSpasInRegion(ability);
    return () => subscription.unsubscribe();
    // dont need to call api again if setter functions change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  useEffect(() => {
    setAllowedSPAsInRegion(ability);
  }, [availableSPAs, ability]);


  useEffect(() => {
    if (!ability) return;
    if (spaConfig && spaConfig.length === 0) return;
    const allowedItems = spaConfig.filter(navItem => {
      const { action, subject } = navItem.ability;
      return action ? ability.can(action, subject) : true;
    });
    console.log('allowedItems = ', allowedItems);
    setNavItmes(allowedItems);
    setIsOnlyStudentHomeSpa(allowedItems.length == 1 && ability.can("VIEW", "STUDENT_HOME"));
  }, [region, spaConfig, ability])

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const updateSpaRoutes = () => {
    // console.log("I am here", ev)
    // if (ev?.key !== "mobileRoutes") return
    // console.log("I am here too")
    const routes = JSON.parse(localStorage.getItem("mobileRoutes"));
    setSpaRoutes(routes)
  }

  const openNotificationPopup = (ev) => {
    ev.stopPropagation()
    setIsNotificationPopupOpen(true);
  }

  const closeNotificationPopup = () => {
    setIsNotificationPopupOpen(false);
  }

  /*Useffect to add eventlisten at window level and this is triggered by the spa to set the routes in the spa  */
  useEffect(() => {
    let routes = localStorage.getItem("mobileRoutes");
    if (routes) {
      routes = JSON.parse(routes)
      setSpaRoutes(routes)
    }
    window.addEventListener("mobileRoutes", updateSpaRoutes);
    console.log("event set", window)
    return () => window.removeEventListener("mobileRoutes", updateSpaRoutes)
  }, [])

  const selectedSpaRoutes = useMemo(() => {
    const selected = navItems.find(it => it.spaId === selectedSpaId)?.description;
    const routes = selected ? spaRoutes?.[selected] ?? [] : [];
    return routes
  }, [selectedSpaId, navItems, spaRoutes]);

  useEffect(() => {

    const selected = selectedSpaRoutes.find(item => {
      const pathToCompare = `${routerBasePath}/application/${encodeURIComponent(selectedSpaId)}${item.path}`
      const matchProfile = matchPath(pathname, {
        path: pathToCompare
      });
      const isChild = pathname.startsWith(pathToCompare) && pathname.length !== pathToCompare.length;
      return (matchProfile && matchProfile.isExact) || isChild;
    });

    if (selected) {
      const pathToCompare = `${routerBasePath}/application/${encodeURIComponent(selectedSpaId)}${selected.path}`
      const isChild = pathname.startsWith(pathToCompare) && pathname.length !== pathToCompare.length;
      setActivePath(selected?.path);
      setShowMenu((isChild && selected.showChildMenu) || !isChild);
    } else {
      setActivePath(selectedSpaRoutes[0]?.path);
      setShowMenu(true);
    }
    if (pathname.includes('/alfresco/player/') || pathname.includes('/digitalLibrary/list/')) {
      setShowMenu(false);
    }

    if (pathname.includes('digitalLibrary/list')) {
      setShowMenu(false);
    }

  }, [selectedSpaRoutes, pathname]);

  const adminLinkClickHandler = (e) => {
    e?.stopPropagation()
    let { prodHostname, stgGatewayHostname, 
      stgGatewaySpaId, prodGatewayHostname, prodGatewaySpaId, 
      qaGatewaySpaId, qaGatewayHostname, perfGatewaySpaId, perfGatewayHostname, stgHostname, qaHostname } = adminLinkConsts
    let gatewayhostname, gatewaySpaId;
    if (window.location.hostname === prodHostname) {
      gatewayhostname = prodGatewayHostname
      gatewaySpaId = prodGatewaySpaId
    } else if (window.location.hostname === stgHostname) {
      gatewayhostname = stgGatewayHostname
      gatewaySpaId = stgGatewaySpaId
    } else if (window.location.hostname === qaHostname) {
      gatewayhostname = qaGatewayHostname
      gatewaySpaId = qaGatewaySpaId
    } else {
      gatewayhostname = perfGatewayHostname
      gatewaySpaId = perfGatewaySpaId
    }
    const adminUrl = `https://${gatewayhostname}/#/application/${gatewaySpaId}/user-list`
    const adminTab = window.open(adminUrl);
    adminTab.history.pushState(null, 'Pearson Global Gateway-Admin-User accounts', adminUrl);
  }

  return (
    <>
      <div className="gwWrapper">
        <LogOutPopUp
          handleLogout={handleLogout}
          show={showlogOutPopUp}
          close={closeLogOutPopUp}
        />
        <div role="header" className="gwWraper_header">
          <Header
            userInitials={userName}
            headerImage={headerImage}
            showDropDown={showDropDown}
            handleHeaderClick={!wrapperLevelErrorPage && handleHeaderClick}
            showWelcomeMsg={
              selectedSpaId === defaultSpaId && !wrapperLevelErrorPage
            }
            selectedHeader={selectedHeader}
            setSelectedHeader={setSelectedHeader}
            handleDrawerToggle={handleDrawerToggle}
            navItems={navItems}
            isNotificationPopupOpen={isNotificationPopupOpen}
            openNotificationPopup={openNotificationPopup}
            closeNotificationPopup={closeNotificationPopup}
            {...headerDetailsProps}
            isMobile={isMobile}
            assessmentPage={assessmentPage}
            adminLinkClickHandler={adminLinkClickHandler}
            isOpen={isOpen}
          >
            <ProfileDropDown
              accountDropdownOptions={activeHubDropdownOptions}
              isOpen={isOpen}
              showDropDown={showDropDown}
            />
          </Header>
        </div>

        <div className="gwSpaWrapper__tabs gwSpaWrapper__tabs--ahub">
          <div className="d-flex">
            <div className="gwWrapper_content">
              {wrapperLevelErrorPage ?
                <div className="gwSpaWrapper__iframe"> {wrapperLevelErrorPage} </div> :
                <>
                  <ResponsiveNavigationMenu isMobile={isMobile} showMenu={showMenu && isOnlyStudentHomeSpa} activePath={activePath} setActivePath={setActivePath} selectedSpaRoutes={selectedSpaRoutes} navItems={navItems} selectedSpaId={selectedSpaId} spaRoutes={spaRoutes} t={t} mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} handleDrawerToggle={handleDrawerToggle} setSelectedSpaId={setSelectedSpaId} adminLinkClickHandler={adminLinkClickHandler} />
                  <div className={` ${isMobile ? "nav-hidden" : isOnlyStudentHomeSpa ? "spa-container" : ""} ${showMenu && selectedSpaRoutes.length ? "" : "nav-hidden"}`}>
                    <App />
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>

      <div role="contentinfo" className="gwWraper_footer">
        <Footer
          footerLinks={footerDetails || []}
          copyRight={headerDetails && headerDetails.copyright}
          toggleAccessibilityDialog={onAccesibilityClick}
          isAccesibilityPopupOpen={showAccesibilityPopup}
        />
      </div>
    </>
  );
}
